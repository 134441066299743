import {useState, useEffect,useRef} from "react";
import {Container,ToggleButton} from './styles'

export function Header(){
    const [open, setOpen] = useState(false);

	function handleClick(){
        console.log(open);
		setOpen(!open);
	}

    function useOutsideAlerter(ref : any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false)
                }
            }
    
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
 

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return <Container active={open}ref={wrapperRef}>
                    <ToggleButton onClick={handleClick} >
						<span className={"bar"}></span>
						<span className={"bar"}></span>
						<span className={"bar"}></span>
					</ToggleButton>
        <div>
        <ul>
            
            <li><a href="#">CDZ</a></li>
            <li><a href="#">NOSSA LOJA</a></li>
            <li><a href="#menu" onClick={handleClick}>CARDÁPIO</a></li>
            <li><a href="#">DELIVERY</a></li>
            <li><a href="#">TRABALHE CONOSCO</a></li>
        </ul>

      
        </div>
    </Container>
}
