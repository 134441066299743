import styled from 'styled-components'

export const Container = styled.div`
display: flex;
width: 100%;
height: 100%;

@media (max-width:958px){
            flex-direction: column;
}
div{
        width: 100%;
        background: var(--green);
        cursor: pointer;
        border: 0;
        background-repeat: no-repeat;
        background-size:cover;
        background-position:center;
        display: flex;
        justify-content: center;
        align-items: center;

        h1{
            color: var(--background);
        }

        @media (max-width:958px){
            height: 33.33%;
        }

        &:hover{
            filter: brightness(0.9);
        }

}        

`
export const Page = styled.div`
    width: 100%;
    padding: 5rem 1rem;
    display: flex;
    text-align: justify;
    height: auto;
    scroll-behavior: smooth;
    h1, h2,h3 {
                color: var(--text-body);
        }

                p{
                    color: var(--text-body);

                 }
    
    @media (max-width:958px){
                width: 100%;
                font-size: 0.9rem;
            }

    div{
        margin: 0.4rem 1rem;
        width: 50%;
        flex-direction:row;

        @media (max-width:958px){
                margin: 0.25rem;
                width: 100%;
                padding: 0.25rem;
                
                h1, h2,h3 {
                padding: 0.25rem;
                text-align: center;

                }

                p{
                    width: 100%;

                 }
        }


        div{
            float:left;
            width: 100%;
            flex-direction: row;

            .buttons{
                width: 3rem;
                margin: 0 10;
                button{
                    width: 100%;
                }

            }
            div{
                width: 60%;
                @media (max-width:958px){
                width: 100%;
                padding: 0;

            }

            }
            @media (max-width:958px){
                width: 100%;

            }

        }
    }

    div::after{
     
        opacity: 0.6;

    }

.img{
    margin: 3rem;
    height: 700px;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
        @media (max-width:958px){
            width: 40%;
            margin: 120px 0;
            display: none;
        }
}


`