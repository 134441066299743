import styled from 'styled-components'

export const Container = styled.div`
div{
    background: var(--shape);
    padding: 1.5rem 2rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    background: var(--black);
    width: 100%;
    min-height: 930px;
    vertical-align: middle;
    padding: 1rem;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    text-align:center;
    justify-content: center;
    filter: grayscale(10%);

    h1{
        font-size:13rem;
        color: var(--text-body);
        font-family: 'Open Sans', sans-serif;
        margin: -3rem;
        @media (max-width:958px){
            font-size:4.0rem;
            max-width:100%;
        }
        
    }

    header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    strong{
        display: block;
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 500;
        line-height:3rem;

    }

    &.highlight-background{
        background: var(--green);
        color: #FFF;
    }

}

`