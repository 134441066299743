import styled from 'styled-components'
interface Props { 
    active: boolean; 
}

export const Container = styled.header<Props>`
    background: #1c1c1c;
    height: 3.5rem;
    position: sticky;
    top: 0;
    span{
                margin: auto;
        }
div{
    max-width: 1120px;
    margin: 0 auto;
    display: flex;


    ul{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li{
            list-style: none;
            padding: 0 3rem; 
            a{
                text-decoration: none;
                padding: 1rem;
                display: block;
                color: var(--text-body);
                font-family: 'Open Sans 400', sans-serif;

            }
            transition: filter 0.2s;
            &:hover{
            filter: brightness(0.6)
        }        }


    }
    button {
        font-size:1rem;
        color: #fff;
        background: var(--red);
        border: 0;
        padding: 0 2rem;
        border-radius:0.25rem;
        height: 3rem;
        
        transition: filter 0.2s;
        &:hover{
            filter: brightness(0.9)
        }
    }

    
}

@media(max-width: 900px){
                flex-direction: column;
                align-items: flex-start;
                        ${props => props.active && `
                        position: fixed; 
                        top: 0;
                        left: 0;
                        height: 100% !important;
                `};
                z-index:99;
                height: 3rem;
                
                ul{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 0 ;
                        text-align: center;

                        
                }
                 span{
                        ${props => props.active && `display: flex`}
                        ${props => !props.active && `display: none`}
                 }
                 li{
                        display: none;
                        ${props => props.active && `display: inline-block;`};
                        text-align: center;
                        width: 95%;
                        margin: 0.51rem;
                        
                        :first-child{
                            margin-top: 5rem;

                        }
                
                 }      
}
`



export const ToggleButton = styled.nav`
                position: fixed;
                top: 0.75rem;
                left: 1rem;
                display: none;
                flex-direction: column;
                justify-content: space-between;
                width: 30px;
                height: 21px;
                cursor: pointer;
         
                .bar {
                height: 3px;
                width: 100%;
                background: #FFF;
                border-radius: 10px;
                display: flex !important;
                }

                @media(max-width: 900px){
                        display: flex;
                }
`