import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body, #root, .App {
  height: 100%;
  scroll-behavior: smooth;

}

:root{
    --background: #000000;
    --red: #e52e4d;
    --blue: #5429cc;
    --green: #33cc95;
    --whatsapp: #25D366;

    --blue-light: #6933ff;
    --text-title: #363f5f;

    --text-body: #f0f2f5;
    --shape: #000000;
    --black: #000000;
}


body, input, textarea,button{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    height: 100%;
}

h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
html{
    height: 100%;

    @media(max-width:1080){
        font-size:93.75%
    }
    @media(max-width:720){
        font-size:87.5%;
    }

}

body{
    background: var(--background);
    -webkit-font-smoothing: antialised;
    height: 100%;


}

button{
    cursor: pointer;

}

[disabled]{
    opacity: 0.6;
    cursor: not-allowed;
}
`