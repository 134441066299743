import styled from 'styled-components'

export const Container = styled.main`

height: 100%;


.button{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 2rem;
        text-align: center;
        text-decoration: none;
        padding: 1rem;
       background: var(--whatsapp);
       height:100%;

        color: var(--shape);
        width: 15rem;
        height: 4rem;
        border: 0;
        border-radius: 0.5rem;
}        

`
