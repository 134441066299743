import {Container} from './styles'
import {Summary} from '../Summary'
import {Store} from '../Stores'
import { Menu } from '../Menu'
import {Footer} from '../footer'

export function Dashboard(){

    return(
            <Container>
                <a href="https://api.whatsapp.com/send?phone=5541991124832&text=Quero%20empad%C3%A3o!" className="button">NOSSO WHATSAPP</a>

                <Summary/>
                <Menu/>
                <Store/>
                <Footer/>
            </Container>
    );
}