import {Container,Page} from './styles'
import {MenuItem} from './Components/MenuItem'

export function Menu(){

    var items : any= [{
        title: 'CARNE DE PANELA COM GORGONZOLA',
        description: 'Massa especial, recheio de carne de panela com gorgonzola',
        price: 'R$ 26,00',
        category:'principal'
    },
    {
        title: 'FRANGO COM REQUEIJÃO CREMOSO',
        description: 'Massa especial, recheio de frango da casa coberto com requeijão cremoso',
        price: 'R$ 17,00',
        category:'principal'
    },
    {
        title: 'PALMITO',
        description: 'Massa especial e recheio de palmito.',
        price: 'R$ 21,00',
        category:'principal'
    },
    {
        title: 'CAMARÃO',
        description: 'Massa especial da casa, delicioso recheio de camarão.',
        price: 'R$ 24,00',
        category:'principal'
    },
    {
        title: 'CAMARÃO ESPECIAL',
        description: 'Massa especial da casa, delicioso recheio de camarão com palmito coberto requeijão cremoso',
        price: 'R$ 26,00',
        category:'principal'
    },
    {
        title: 'STROGONOFF DE CARNE',
        description: 'Massa especial da casa, delicioso recheio de camarão com palmito coberto requeijão cremoso',
        price: 'R$ 26,00',
        category:'principal'
    },
    {
        title: 'FRANGO COM PALMITO',
        description: 'Massa especial da casa, delicioso recheio de camarão com palmito coberto requeijão cremoso',
        price: 'R$ 26,00',
        category:'principal'
    },
    {
        title: 'FRANGO COM CHEDDAR E BACON',
        description: 'Massa especial, recheio de frango da casa coberto cheddar e bacon',
        price: 'R$ 19,00',
        category:'principal'
    },
    {
        title: 'DADINHO DE TAPIOCA',
        description: 'Delicioso carne de panela com GORGONZOLA',
        price: 'R$ 12,00',
        category:'acompanhamento'
    },

    {
        title: 'MAIONESE DE BATATA + FAROFA DE BACON',
        description: 'Delicioso carne de panela com GORGONZOLA',
        price: 'R$ 12,00',
        category:'acompanhamento'
    },
    {
        title: 'SALADA CAESAR',
        description: 'Delicioso carne de panela com GORGONZOLA',
        price: 'R$ 6,00',
        category:'acompanhamento'
    },
    {
        title: 'TORTINHA DE BRIGADEIRO',
        description: 'Massa da casa, recheio de brigadeiro feito com 50% cacau.',
        price: 'R$ 7,00',
        category:'doces'
    },
    {
        title: 'TORTINHA DE ROMEU E JULIETA',
        description: 'Massa da casa, recheio de goiabada com queijo minas.',
        price: 'R$ 7,00',
        category:'doces'
    },
    {
        title: 'TORTINHA DE MAÇÃ COM CANELA',
        description: 'Massa da casa, recheio de maçã caramelizada com canela.',
        price: 'R$ 7,00',
        category:'doces'
    },
    {
        title: 'TORTINHA DE CREME DE AVELÃ',
        description: 'Massa da casa, recheio de creme de avelã.',
        price: 'R$ 7,00',
        category:'doces'
    },
    {
        title: 'TORTA GELADA DE CAFÉ',
        description: '',
        price: 'R$ 12,00',
        category:'doces'
    },
    {
        title: 'CHEESECAKE DESCONSTRUÍDO',
        description: '',
        price: 'R$ 14,00',
        category:'doces'
    },
    {
        title: 'ESPRESSO',
        description: '30ml de café espresso',
        price: 'R$ 6,00',
        category:'bebidas'
    },
    {
        title: 'CAPPUCCINO BRASILEIRO',
        description: '',
        price: 'R$ 6,00',
        category:'bebidas'
    },
    {
        title: 'SODA ITALIANA',
        description: 'Sabores: Cranberry, Limão, Amora',
        price: 'R$ 6,00',
        category:'bebidas'
    },
    {
        title: 'REFRIGERANTES',
        description: 'Coca-cola, Coca-cola Zero, Schweppes',
        price: 'R$ 6,00',
        category:'bebidas'
    },
    {
        title: 'VINHOS - EM BREVE',
        description: '',
        price: '',
        category:'bebidas'
    },
]

    return(
        <>
            {/* <Container id={"menu"}>
                <div  style={{  backgroundImage: "url(/PRINCIPAL.PNG)"}} onClick={() => window.location.href = '#principal'}>
                    <h1>PRINCIPAL</h1>
                </div>
                <div  style={{  backgroundImage: "url(/DOCES.PNG)"}} onClick={() => window.location.href = '#doces'}>
                <h1>DOCES</h1>
                </div>
                <div  style={{  backgroundImage: "url(/BEBIDAS.PNG)"}} onClick={() => window.location.href = '#bebidas'}>
                <h1>CAFÉ E VINHO</h1>
                </div>

            </Container> */}
            <Page>
            <div id={"principal"} >
                <h2>EMPADÃO INDIVIDUAL</h2> 
                <p>Todas os sabores tem aproximadamente 400g</p>
                {/* <p>Temos o melhor empadão do mundo. Ele é o carro chefe da nossa cozinha. O nosso empadão individual é feito em massa folheada, portanto não é pesado, não gruda no céu da boca, o fato de ter as tirinhas ao invés de uma cobertura de massa, o empadão possui mais recheio do que massa, sendo uma ótima opção de um almoço/jantar. Possui um formato redondo com aproximadamente 400g. Serve muito bem uma pessoa. </p> */}
                    {items.map((i : any) => {
                        if(i.category === 'principal' )
                        return(
                       <MenuItem title={i.title} description={i.description} price={i.price}/>
                        ) 
                    }
                    )}
            </div>
            <div className={"img"}  style={{  backgroundImage: "url(/PRINCIPAL.PNG)"}} />
            </Page>

            <Page>
            <div id={"porcoes"}>
            <h2>PORÇÕES</h2>
            {items.map((i : any) => {
                        if(i.category === 'acompanhamento' )
                        return(
                            <MenuItem title={i.title} description={i.description} price={i.price}/>
                        ) 
                    }
                    )}
            
            </div>
            {/* <div className={"img"}  style={{  backgroundImage: "url(/PRINCIPAL.PNG)"}} ></div> */}
            
            </Page>

            <Page>
            <div id={"doces"}>
            <h2>DOCES</h2>
            {items.map((i : any) => {
                        return(
                            i.category === 'doces'  ? 
                            <MenuItem title={i.title} description={i.description} price={i.price}/>
                            : <></>
                        ) 
                }
            )}
            </div>
            <div className={"img"}  style={{  backgroundImage: "url(/DOCES.PNG)"}} ></div>

            </Page>


            <Page>
            <div id={"bebidas"}>
            <h2>BEBIDAS</h2>
            {items.map((i : any) => {
                        if(i.category === 'bebidas' )
                        return(
                            <MenuItem title={i.title} description={i.description} price={i.price}/>
                        ) 
                    }
                    )}
            </div>
            <div className={"img"}  style={{  backgroundImage: "url(/BEBIDAS.PNG)"}} ></div>

            </Page>
            </>
    );
}
