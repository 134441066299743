import {Container} from './styles'
interface Item{
    title: string,
    description: string,
    price: string
}
export function MenuItem(i: Item){

    return(
        <Container>
            {/* <div className="buttons">
                <button  className="buttons">+</button>
                <button  className="buttons">-</button>
            </div> */}
            <div>
                <h3>{i.title}</h3>                    
                <p>{i.description}</p>
                {/* <p>{i.price}</p> */}
            </div>
            <div><p>{i.price}</p></div>

        </Container>
    )
}