import {Container} from './styles'

export function Store(){

    return(
        <>
            <Container id={"menu"}>
                <div >
                <p>Culinária da Zeni</p>
                <p>Rua Brigadeiro Franco, 254 - Mercês</p>
                <p>Curitiba, Paraná</p>
                </div>

            </Container>
            </>
    );
}
