import {Container} from './styles'

export function Summary(){

    return (
        <Container>
                <div style={{  backgroundImage: "url(/summary.png)"}}
                  >
                    <h1>O MELHOR</h1>
                    <h1> EMPADÃO</h1>
                    <h1>DA CIDADE</h1>

                </div>
        </Container>
    )
}
